import React from "react";
import { makeStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import WhiteLogo from "../assets/W_Logo.svg";

interface Props {
  score: number;
  startOver: () => void;
}

export default (props: Props) => {
  const styles = useStyles({});
  const gotNuclearUrl = "https://www.gotnuclear.net";
  return (
    <nav className={styles.root}>
      <Button variant="contained" onClick={() => props.startOver()}>
        Start Over
      </Button>
      {window.screen.width > 600 ? (
        <Typography variant="h3" component="h1">
          <Link href={gotNuclearUrl} className={styles.gotNuclearLink}>
            Got Nuclear
          </Link>
        </Typography>
      ) : (
        <Link href={gotNuclearUrl} className={styles.gotNuclearLink}>
          <img className={styles.mobileLogo} src={WhiteLogo} alt="mobile-logo" />
        </Link>
      )}
      <Typography variant="h5" component="body">
        Score: {props.score}
      </Typography>
    </nav>
  );
};

export const useStyles = makeStyles((theme: AugmentedTheme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      paddingBottom: "2vh",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 1400,
      paddingTop: 25,
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 15,
      fontSize: 1,
    },
    "& > *": {
      marginRight: 10,
    },
  },
  gotNuclearLink: {
    textDecoration: "none",
    color: "white",
  },
  mobileLogo: {
    height: "10vh",
    width: "10vh",
  },
}));

import React from "react";
import { makeStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardBackImage from "../assets/GotNuclearLOGOBlue.svg";
import CardMedia from "@material-ui/core/CardMedia";
import Card1 from "../assets/01Coalkilla.jpg";
import Card2 from "../assets/02PlugitIn.jpg";
import Card3 from "../assets/03AQI_V2.jpg";
import Card4 from "../assets/04CoalIsDirty.jpg";
import Card5 from "../assets/05Radioactive.jpg";
import Card6 from "../assets/06Buds.jpg";
import Card7 from "../assets/07SteamSoClean.jpg";
import Card8 from "../assets/08SuperFreash.jpg";

const CARD_IMGS = [Card1, Card2, Card3, Card4, Card5, Card6, Card7, Card8];

export interface Props {
  value: number;
  isOpen: boolean;
  isEnabled: boolean;
  onClick: () => void;
}

export default (props: Props) => {
  const { value } = props;
  const styles = useStyles({});
  const onClick = () => {
    if (props.isEnabled) {
      props.onClick();
    }
  };
  return (
    <div
      className={`${styles.root} ${!props.isEnabled && styles.disabled}`}
      onClick={onClick}
    >
      <div
        className={`${styles.flipper} ${
          (props.isOpen || !props.isEnabled) && styles.flipped
        }`}
      >
        <div className={styles.back}>
          <Card>
            <CardMedia className={styles.cardMedia} image={CardBackImage} />
          </Card>
        </div>

        <div className={styles.front}>
          <Card>
            <CardMedia className={styles.cardMedia} image={CARD_IMGS[value]} />
          </Card>
        </div>
      </div>
    </div>
  );
};

const mobileCardDim = {
  height: "28vw",
  width: "28vw",
};

const tabletDim = {
  height: "20vw",
  width: "20vw",
};

const cardDim = {
  width: "22vh",
  height: "22vh",
};

export const useStyles = makeStyles((theme: AugmentedTheme) => ({
  root: {
    userSelect: "none",
    perspective: 1000,
    transition:
      "perspective 0.5s cubic-bezier(.64,-0.57,.1,1), opacity 0.3s linear",
    transform: "rotateY(0deg)",
    "&:hover": {
      cursor: "pointer",
    },
    "& $back, & $front": {
      background: "#ffff",
      borderRadius: "1vh",
      backfaceVisibility: "hidden",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: "100%",
    },
    [theme.breakpoints.down("xs")]: mobileCardDim,
    [theme.breakpoints.up("sm")]: tabletDim,
    [theme.breakpoints.up("lg")]: cardDim,
  },
  cardMedia: {
    [theme.breakpoints.down("xs")]: mobileCardDim,
    [theme.breakpoints.up("sm")]: tabletDim,
    [theme.breakpoints.up("lg")]: cardDim,
  },
  disabled: {
    opacity: "20%",
    cursor: "default",
  },
  flipper: {
    transform: "rotateY(0deg)",
    transition: "0.6s ease-out",
    transformStyle: "preserve-3d",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  flipped: {
    transform: "rotateY(180deg)",
    cursor: "default",
    "& $back": {
      zIndex: 2,
    },
    "& $front": {
      zIndex: 0,
    },
  },
  back: {
    transform: "rotateY(0deg)",
    zIndex: 2,
  },
  front: {
    display: "grid",
    gridTemplate: `
            "s1 .  . " auto
            ".  vl . " auto
            ".  .  s2" auto
            / auto auto auto`,
    justifyItems: "center",
    alignItems: "center",
    transform: "rotateY(180deg)",
  },
  suit: {
    color: "var(--accent)",
    fontSize: "2vh",
    "&:first-child": {
      gridArea: "s1",
    },
    "&:last-child": {
      gridArea: "s2",
    },
  },
  value: {
    gridArea: "vl",
    fontSize: "3vh",
  },
}));

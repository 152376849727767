import React, { useState } from "react";
import { makeStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import Board from "./components/Board";
import Modal from "./components/Modal";
import Navbar from "./components/Navbar";
import config from "./config.json";

export default () => {
  const styles = useStyles({});
  const [boardKey, setBoardKey] = useState(0);
  const [score, setScore] = useState(config.startingScore);
  const [isWin, setIsWin] = useState(false);
  const onReset = () => {
    setBoardKey((k) => k + 1);
    setScore(config.startingScore);
    setIsWin(false);
  };
  return (
    <div className={styles.root}>
      <Helmet>
        <title>Got Nuclear Memory Game</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Got Nuclear Memory Game" />
        <meta property="og:url" content={window.location.origin} />
        <meta
          property="og:description"
          content="Fun memory game about nuclear energy."
        />
      </Helmet>
      {window.screen.width >= 600 && (
        <Navbar score={score} startOver={onReset} />
      )}
      <Board
        numCards={config.cards}
        key={boardKey}
        onFail={() => setScore((s) => s + config.matchFailPoints)}
        onMatch={() => setScore((s) => s + config.matchPoints)}
        onWin={() => setIsWin(true)}
      />
      {window.screen.width < 600 && (
        <Navbar score={score} startOver={onReset} />
      )}
      {isWin && (
        <Modal>
          <div className={styles.Content}>
            <div className={styles.Title}>You Win!</div>  
            <div>Score: {score}</div>
            <div className={styles.Button} onClick={onReset}>
              Play Again
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export const useStyles = makeStyles((theme: AugmentedTheme) => ({
  root: {
    width: "100vw",
    height: "100vh",
  },
  Content: {
    padding: 10,
  },
  Title: {
    fontSize: 72,
    marginBottom: 20,
  },
  Button: {
    color: "var(--accent)",
    cursor: "pointer",
    textAlign: "right",
  },
}));
